.main{
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    margin: 0;
    height: calc(100vh - 100px);
    /* padding: 0px 60%; */
    box-sizing: border-box;
    background: white;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.find{
    margin-top: 50px;
    background: black;
    
    /* height: 200px; */
    /* width: 50%; */
    padding: 50px 100px;

    outline: 2px solid rgb(31, 31, 31);
    outline-offset: 15px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.find-box{
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 555px;

    transition: box-shadow 0.4s;
}

.find-box:hover, .find-box:active{
    box-shadow: 0px 0px 20px white;    
}

.find-search{
    padding: 5px 10px;
    box-sizing: border-box;
    height: 40px;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    font-weight: 700;

    background: black;
    color: white;

    outline: none;
    border: 1px solid white;
    border-radius: 555px 0px 0px 555px;

    text-align: center;
    transition: box-shadow 0.4s;

    gap: 5px;
}

.find-search::placeholder{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.505);
}

.find-search:active, .find-search:focus, .find-search:hover{
    outline: none;
}

.find-search-btn{
    padding: 10px 20px;

    background: black;
    color: white;

    outline: none;
    border: 1px solid white;
    border-radius: 0px 555px 555px 0px;

    text-align: center;
    transition: box-shadow 0.4s;
    height: 40px;
    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.find-search-btn img{
    height: 30px;
    transition: transform 0.4s ease;
}

.find-search-btn img:hover{
    transform: scale(1.05);
    transform: rotate(180deg);
}

.results{
    margin-top: 50px;
    background: black;
    
    height: 300px;
    width: 75%;

    outline: 2px solid rgb(31, 31, 31);
    outline-offset: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
}