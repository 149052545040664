.main{
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    margin: 0;
    height: calc(100vh - 100px);
    /* padding: 0px 60%; */
    box-sizing: border-box;
    background: black;

    display: flex;
    justify-content: center;
    /* align-items: center; */
}

.profile{
    width: 100%;
    height: 100%;
    /* padding: 50px 100px; */
    border-radius: 10px;
    box-sizing: border-box;
    /* border: 1px solid white; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-inner{
    position: relative;
    width: 500px;
    height: 50%;
    box-sizing: border-box;
    outline: 2px solid rgb(31, 31, 31);
    outline-offset: 15px;
}

.profile-banner{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    height: 166px;
}

.profile-img{
    position: absolute;
    left: 15%;
    top: 100px;
    transform: translate(-50%, 0);
    border-radius: 999px;
    height: 120px;
    border: 3px solid rgb(0, 0, 0)
}

.profile-username{
    position: absolute;
    left: 5%;
    top: 270px;
    color: white;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
}

.profile-name{
    position: absolute;
    left: 5%;
    top: 240px;
    color: white;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
}

.wallet-connect{
    position: absolute;
    right: 0px;
    top: 180px;
    /* transform: translate(-50%, 0); */
}

.twitter-icon{
    position: absolute;
    right: 0px;
    top: 240px;

    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    font-weight: 700;
    background-color: white;
    border-radius: 5px;
    transition: opacity 0.4s ease;
}

.twitter-icon:hover{
    opacity: 0.7;
}

.twitter-icon-img{
    height: 20px;
}

.connect-twitter{
    border: 1px solid white;
    border-radius: 999px;
    padding: 10px 20px;
    color: white;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

.connect-twitter-img{
    width: 25px;
}

.connect-twitter-btn{
    border: 1px solid white;
    border-radius: 999px;
    padding: 10px 30px;
    color: white;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: border 0.4s ease;
}

.connect-twitter-btn:hover{
    border: 1px solid #26a7de;
}