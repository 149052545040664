.home-main{
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    margin: auto;
    /* height: calc(100vh - 100px); */
    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-align: center;
}
.home{
    background-image: url("./bg.jpg");
    /* border-top: 1px solid #c298dc; */
    border-bottom: 1px solid #c298dc;
    width: 100%;
    margin: auto;
    height: calc(100vh - 100px);
    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-align: center;
}

/* .home-title-box */

.home-title{
    font-size: 40px;
    font-weight: 700;
    color: white;
}

.home-subtitle{
    font-size: 16px;
    font-weight: 500;
    color: white;
}

.home-directions{
    margin: 100px 0px;
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
}

.home-directions div{
    color: white;
    font-weight: 600;
    font-size: 20px;
}

.home-directions div a{
    color: #c298dc;
    font-weight: 600;
    font-size: 20px;
    transition: color .4s;
}

.home-directions div a:hover{
    color: rgba(244,244,245,.4)
  }


.home-dir-1{
    transform: rotate(-5deg);
}

.home-dir-2{
    transform: rotate(5deg);
}

.home-dir-3{
    transform: rotate(-5deg);
}

.home-dir-4{
    font-weight: 700;
    transform: rotate(5deg);
}

.pointer-box{
    width: 60px;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    transition: transform 1s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pointer{
    border: 1px solid white;
    height: 50px;
    width: 0;
    transition: transform 1s ease;
}

.pointer-box:hover{
    transform: rotate(360deg);
}

.security-section{
    margin: 100px 0px;
    width: 100%;
    height: 50%;
    border-top: 1px solid #c298dc;
    padding: 50px;
    box-sizing: border-box;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
}

.security-title{
    color: white;
    font-weight: 700;
    font-size: 40px;
}

.security-box{
    padding: 10px 30px;
    width: 50%;
    box-sizing: border-box;
    text-align: left;
}

.security-subtitle{
    text-align: left;
    color: white;
    font-weight: 600;
    font-size: 24px;
}

.security-subtitle-box{
    margin-bottom: 10px;
    transition: transform 0.4s ease;
}

.security-subtitle-box:hover{
    transform: rotate(-2deg);
}


.security-info{
    text-align: left;
    color: white;
    font-weight: 500;
    font-size: 16px;
}

.footer-section{
    /* margin: 100px 0px; */
    width: 100%;
    height: 50%;
    border-top: 1px solid #c298dc;
    padding: 50px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

/* .footer-section div{

} */

.author{
    transition: transform 0.4s ease;
}

.author:hover{
    transform: rotate(360deg);
}

.author-link{
    color: #c298dc;
    transition: color 0.4s ease;
}

.author-link:hover{
    color: rgba(244,244,245,.4);
}