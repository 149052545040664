@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;700&display=swap');

html, body{
  margin: 0;
  padding: 0;
}

body{
  background: #000000;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

a{
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.header{
  position: absolute;
  top: 0;
  left:0;
  height: 100px;
  width: 100%;

  text-align: center;
  display: flex;
  justify-content: center;

  box-sizing: border-box;
  background: black;
}


.logo img{
  height: 100px;
}

.nav{
  width: 60%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 30px;
}

.nav-active{
  color: rgb(194, 152, 220)
}

.nav-dir{
  font-size: 20px;
  color: white;
  font-weight: 700;

  display: flex;
  justify-content: center;

  gap: 50px;
}

.nav-dir-a{
  transition: color .4s;
}

.nav-dir-a:hover{
  color: rgba(244,244,245,.4)
}

.wallet{
  align-self: right;
}